import { $win } from '../utils/globals';

$('.menu-item-has-children > a').on('click', function (e) {
	e.preventDefault();

	$(this).parent()
		.toggleClass('is-open')
		.siblings()
		.removeClass('is-open');
});
