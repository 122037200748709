import Swiper from 'swiper/swiper-bundle';

new Swiper('.js-slider .swiper', {
	allowTouchMove: true,
	speed: 800,
	slidesPerView: 1,
	effect: 'fade',
	fadeEffect: {
		crossFade: true
	},
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
		enabled: true,		
	},
});
