import { $body } from "../utils/globals.js";
 
$('.js-burger').on('click', function(e) {
	e.preventDefault();

	$(this)
		.toggleClass('is-open');

	$('.nav')
		.toggleClass('is-open');

	$('.header')
		.toggleClass('is-open');

	$body
		.toggleClass('stop-scroll');
});
